.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.custom-white-color {
  background-color: white !important; 
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.checkbox-wrapper-220 .slider1.round1:before {
  border-radius: 50% !important;
}

.checkbox-wrapper-31:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.checkbox-wrapper-31 .background {
  fill: #ccc;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 input[type=checkbox] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  appearance: none;
  -webkit-appearance: none;
}

.checkbox-wrapper-31 input[type=checkbox]:hover {
  cursor: pointer;
}

.checkbox-wrapper-31 input[type=checkbox]:checked+svg .background {
  fill: #6cbe45;
}

.checkbox-wrapper-31 input[type=checkbox]:checked+svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 input[type=checkbox]:checked+svg .check {
  stroke-dashoffset: 0;
}






  .checkbox-wrapper-27 .checkbox {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .checkbox-wrapper-27 .checkbox > input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .checkbox-wrapper-27 .checkbox__icon {
    display: inline-block;
    color: #999;
    vertical-align: middle;
    margin-right: 5px;
  }
  .checkbox-wrapper-27 input[type="checkbox"]:checked ~ .checkbox__icon {
    color: #5bcfc5;
  }

  .checkbox-wrapper-27 .checkbox__icon:before {
    font-family: "icons-27";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .checkbox-wrapper-27 .icon--check:before,
  .checkbox-wrapper-27 input[type="checkbox"]:checked ~ .checkbox__icon:before {
    content: "\e601";
  }

  .checkbox-wrapper-27 .icon--check-empty:before,
  .checkbox-wrapper-27 .checkbox__icon:before {
    content: "\e600";
  }

  @font-face {
    font-family: "icons-27";
    font-weight: normal;
    font-style: normal;
    src: url("data:application/x-font-woff;charset=utf-8;base64,d09GRk9UVE8AAAR4AAoAAAAABDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAPgAAAD4fZUAVE9TLzIAAAHsAAAAYAAAAGAIIvy3Y21hcAAAAkwAAABMAAAATBpVzFhnYXNwAAACmAAAAAgAAAAIAAAAEGhlYWQAAAKgAAAANgAAADYAeswzaGhlYQAAAtgAAAAkAAAAJAPiAedobXR4AAAC/AAAABgAAAAYBQAAAG1heHAAAAMUAAAABgAAAAYABlAAbmFtZQAAAxwAAAE5AAABOUQYtNZwb3N0AAAEWAAAACAAAAAgAAMAAAEABAQAAQEBCGljb21vb24AAQIAAQA6+BwC+BsD+BgEHgoAGVP/i4seCgAZU/+LiwwHi2v4lPh0BR0AAAB8Dx0AAACBER0AAAAJHQAAAO8SAAcBAQgPERMWGyBpY29tb29uaWNvbW9vbnUwdTF1MjB1RTYwMHVFNjAxAAACAYkABAAGAQEEBwoNL2X8lA78lA78lA77lA6L+HQVi/yU+JSLi/iU/JSLBd83Fffsi4v77Pvsi4v37AUOi/h0FYv8lPiUi4v33zc3i/s3++yLi/fs9zeL398F9wCFFftN+05JzUdI9xr7GveR95FHzwUO+JQU+JQViwwKAAMCAAGQAAUAAAFMAWYAAABHAUwBZgAAAPUAGQCEAAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAAAAAQAAA5gEB4P/g/+AB4AAgAAAAAQAAAAAAAAAAAAAAIAAAAAAAAgAAAAMAAAAUAAMAAQAAABQABAA4AAAACgAIAAIAAgABACDmAf/9//8AAAAAACDmAP/9//8AAf/jGgQAAwABAAAAAAAAAAAAAAABAAH//wAPAAEAAAAAAACkYCfgXw889QALAgAAAAAAz65FuwAAAADPrkW7AAD/4AIAAeAAAAAIAAIAAAAAAAAAAQAAAeD/4AAAAgAAAAAAAgAAAQAAAAAAAAAAAAAAAAAAAAYAAAAAAAAAAAAAAAABAAAAAgAAAAIAAAAAAFAAAAYAAAAAAA4ArgABAAAAAAABAA4AAAABAAAAAAACAA4ARwABAAAAAAADAA4AJAABAAAAAAAEAA4AVQABAAAAAAAFABYADgABAAAAAAAGAAcAMgABAAAAAAAKACgAYwADAAEECQABAA4AAAADAAEECQACAA4ARwADAAEECQADAA4AJAADAAEECQAEAA4AVQADAAEECQAFABYADgADAAEECQAGAA4AOQADAAEECQAKACgAYwBpAGMAbwBtAG8AbwBuAFYAZQByAHMAaQBvAG4AIAAxAC4AMABpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuAFIAZQBnAHUAbABhAHIAaQBjAG8AbQBvAG8AbgBHAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4AAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==") format("woff");
  }

  .input-box-shadow{
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }



  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
 
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }


  .container-pinterest
{
  padding-top: 13vh;
  width: 1400px;
  margin: auto;
  columns: 4;
  column-gap: 20px;
  
}
.container-pinterest .box
{
  width: 100%;
  margin-bottom: 10px;
  break-inside: avoid;
  border-radius: 15px;
}
.container-pinterest .box img
{
  max-width: 100%;
  min-width: 100%;
  border-radius: 10px;
}


img:hover {
  
  opacity: .95;
  box-shadow: 0 0 10px #fff;
}

.caption{
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
	padding: 2px;
}

@media (max-width: 1427px)
{
  .container-pinterest
  {
    columns: 3;
    width: calc(100% - 40px);
  }
}
@media (max-width: 1200px)
{
  .container-pinterest
  {
    columns: 2;
  }
}

@media (max-width: 1000px)
{
  .container-pinterest
  {
    columns: 2;
  }
  
  
  body{
		overflow-x: hidden;
	}
	
	.navLink{
	    position: absolute;
	    right: 0px;
	    height: 90vh;
	    top: 10vh;
	    background-color: #000; 
	    display: flex;
	    flex-direction: column;
		align-items: center;
		width: 30%;
		transform: translateX(100%);
		transition: transform 0.4s ease-in;
		opacity: 0.9;
	}
	
	.navLink li{
		opacity: 1;
	}
	
	.lines{
		display: block;
	}
	
	.nav-active{
		transform: translateX(0%);
	}
	
	@keyframes navLinkFade{
		from{
			opacity: 0;
			transform: translateX(50px);
			}
		
		to{
			opacity: 1;
			transform: translateX(0px);
			}
		}
	
	
	.toggle .l1{
		transform: rotate(-45deg) translate(-5px,6px);
	}
	
	.toggle .l2{
		opacity: 0;
	}
	
	.toggle .l3{
		transform: rotate(45deg) translate(-5px,-6px);
	}
}
  
  


@media (max-width: 786px)
{
  .container-pinterest
  {
    columns: 2;
  }
}


@media (max-width: 480px)
{
  .container-pinterest
  {
    columns: 1;
  }
}

.box{

  width: 25rem;

}



.container-pinterest111
{
  padding-top: 13vh;
  width: 1400px;
  margin: auto;
  columns: 4;
  column-gap: 20px;
  
}
.container-pinterest111 .box
{
  width: 100%;
  margin-bottom: 10px;
  break-inside: avoid;
  border-radius: 15px;
}
.container-pinterest111 .box img
{
  max-width: 100%;
  min-width: 100%;
  border-radius: 10px;
}


img:hover {
  
  opacity: .95;
  box-shadow: 0 0 10px #fff;
}

.caption{
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
	padding: 2px;
}

@media (max-width: 1427px)
{
  .container-pinterest111
  {
    columns: 3;
    width: calc(100% - 40px);
  }
}
@media (max-width: 1200px)
{
  .container-pinterest111
  {
    columns: 2;
  }
}

@media (max-width: 1000px)
{
  .container-pinterest111
  {
    columns: 2;
  }
  
  
  body{
		overflow-x: hidden;
	}
	
	.navLink{
	    position: absolute;
	    right: 0px;
	    height: 90vh;
	    top: 10vh;
	    background-color: #000; 
	    display: flex;
	    flex-direction: column;
		align-items: center;
		width: 30%;
		transform: translateX(100%);
		transition: transform 0.4s ease-in;
		opacity: 0.9;
	}
	
	.navLink li{
		opacity: 1;
	}
	
	.lines{
		display: block;
	}
	
	.nav-active{
		transform: translateX(0%);
	}
	
	@keyframes navLinkFade{
		from{
			opacity: 0;
			transform: translateX(50px);
			}
		
		to{
			opacity: 1;
			transform: translateX(0px);
			}
		}
	
	
	.toggle .l1{
		transform: rotate(-45deg) translate(-5px,6px);
	}
	
	.toggle .l2{
		opacity: 0;
	}
	
	.toggle .l3{
		transform: rotate(45deg) translate(-5px,-6px);
	}
}
  
  


@media (max-width: 786px)
{
  .container-pinterest111
  {
    columns: 2;
  }
}


@media (max-width: 480px)
{
  .container-pinterest111
  {
    columns: 1;
  }
}

.box{

  width: 20rem;
 
}





.input-22{
color: rgba(0,0,0,.87);
border-bottom-color: rgba(0,0,0,.42);
font-size: 1rem;
font-weight: 400;
letter-spacing: .009375em;
text-decoration: inherit;
text-transform: inherit;
align-self: flex-end;
box-sizing: border-box;
width: 100%;
padding: 20px 16px 6px;
transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
border: none;
border-bottom: 1px solid;
border-radius: 4px 4px 0 0;
background: rgb(245, 245, 245);
height: 56px;}

.input-22:hover{
    border-bottom-color: rgba(0,0,0,.87);
    background: #ececec;
}
.input-22:focus{
  
  border-color: #6200ee;
}




.container-pinterest333
{
  padding-top: 13vh;
  width: 850px;
  margin: auto;
  columns: 2;
  column-gap: 20px;
  
}
.container-pinterest333 .box333
{
  width: 100%;
  margin-bottom: 10px;
  break-inside: avoid;
  border-radius: 15px;
}
.container-pinterest333 .box333 img
{
  max-width: 100%;
  min-width: 100%;
  border-radius: 10px;
}


img:hover {
  
  opacity: .95;
  box-shadow: 0 0 10px #fff;
}

.caption{
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
	padding: 2px;
}

@media (max-width: 1427px)
{
  .container-pinterest333
  {
    columns: 2;
    width: calc(100% - 40px);
  }
}
@media (max-width: 1200px)
{
  .container-pinterest333
  {
    columns: 2;
  }
}

@media (max-width: 1000px)
{
  .container-pinterest333
  {
    columns: 2;
  }
  
  
  body{
		overflow-x: hidden;
	}
	
	.navLink{
	    position: absolute;
	    right: 0px;
	    height: 90vh;
	    top: 10vh;
	    background-color: #000; 
	    display: flex;
	    flex-direction: column;
		align-items: center;
		width: 30%;
		transform: translateX(100%);
		transition: transform 0.4s ease-in;
		opacity: 0.9;
	}
	
	.navLink li{
		opacity: 1;
	}
	
	.lines{
		display: block;
	}
	
	.nav-active{
		transform: translateX(0%);
	}
	
	@keyframes navLinkFade{
		from{
			opacity: 0;
			transform: translateX(50px);
			}
		
		to{
			opacity: 1;
			transform: translateX(0px);
			}
		}
	
	
	.toggle .l1{
		transform: rotate(-45deg) translate(-5px,6px);
	}
	
	.toggle .l2{
		opacity: 0;
	}
	
	.toggle .l3{
		transform: rotate(45deg) translate(-5px,-6px);
	}
}
  
  


@media (max-width: 786px)
{
  .container-pinterest333
  {
    columns: 2;
  }
}


@media (max-width: 480px)
{
  .container-pinterest333
  {
    columns: 1;
  }
}

.box333{

  width: 15rem;
 
}


.container-pinterest4 {
  width: 1000px;
  margin: auto;
  columns: 3;
  column-gap: 20px;

}

.container-pinterest4 .box4 {
  width: 100%;
  margin-bottom: 10px;
  break-inside: avoid;
  border-radius: 15px;
}

.container-pinterest4 .box4 img {
  max-width: 100%;
  min-width: 100%;
  border-radius: 10px;
}

.container-pinterest4 .box4 .card-body .compnay-name {
  height: 50px;
}

img:hover {

  opacity: .95;
  box-shadow: 0 0 10px #fff;
}

.caption {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  padding: 2px;
}

@media (max-width: 1427px) {
  .container-pinterest4 {
    columns: 3;
    width: calc(100% - 40px);
  }
}

@media (max-width: 1200px) {
  .container-pinterest4 {
    columns: 2;
  }
}

@media (max-width: 1000px) {
  .container-pinterest4 {
    columns: 1;
  }


  body {
    overflow-x: hidden;
  }

  .navLink {
    position: absolute;
    right: 0px;
    height: 90vh;
    top: 10vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    transform: translateX(100%);
    transition: transform 0.4s ease-in;
    opacity: 0.9;
  }

  .navLink li {
    opacity: 1;
  }

  .lines {
    display: block;
  }

  .nav-active {
    transform: translateX(0%);
  }

  @keyframes navLinkFade {
    from {
      opacity: 0;
      transform: translateX(50px);
    }

    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }


  .toggle .l1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle .l2 {
    opacity: 0;
  }

  .toggle .l3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}




@media (max-width: 786px) {
  .container-pinterest4 {
    columns: 1;
  }
}


@media (max-width: 480px) {
  .container-pinterest4 {
    columns: 1;
  }
}

.box4 {

  width: 15rem;

}

.carousel-image {
  width: 150px; /* Set the desired width */
  height: 150px; /* Set the desired height */
  object-fit: cover; /* Ensure the image covers the entire area */
}

.badge-hover {
  transition: background-color 0.3s ease;
}

.badge-hover:hover {
  background-color: #0056b3;
  /* Adjust this color as needed */
}