.report-icon {
   
    transition: transform 0.2s ease-in-out;
  }
  
.report-icon:hover {
    transform: scale(1.1);
    cursor: pointer;
 }

.error_code_strip{
   background-color: var(--bs-body-bg);
}